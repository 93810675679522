<template>
  <div class="Market">
    <div class="bigImg">
    </div>
    <div class="amTitle">
      <p @click="changeModel(1)">
        <span :class="control === 1 ? 'clickSpan' : '' ">财经日历</span>
        <img v-if="control === 1" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(2)">
        <span :class="control === 2 ? 'clickSpan' : '' ">热点新闻</span>
        <img v-if="control === 2" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(3)">
        <span :class="control === 3 ? 'clickSpan' : '' ">市场快讯</span>
        <img v-if="control ===3" src="../../static/index/amRed.png" alt="">
      </p>
      <p @click="changeModel(4)">
        <span :class="control === 4 ? 'clickSpan' : '' ">专家评论</span>
        <img v-if="control === 4" src="../../static/index/amRed.png" alt="">
      </p>
    </div>
    <div class="contentMarket">
      <keep-alive>
        <calendar v-if="control === 1"></calendar>
      </keep-alive>
      <keep-alive>
        <news v-if="control === 2"></news>
      </keep-alive>
      <keep-alive>
        <fast v-if="control === 3"></fast>
      </keep-alive>
      <keep-alive>
        <expert v-if="control === 4"></expert>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Calendar from './market/calendar.vue';
import News from './market/news.vue';
import Fast from './market/fast.vue';
import Expert from './market/expert.vue';
export default {
  name: 'Market',
  components:{
    Calendar,
    News,
    Fast,
    Expert,
  },
  metaInfo: {  
      title: '红狮金业,红狮启富,实时行情资讯,现货黄金白银,黄金投资学习,投资教程',  
      meta: [  
        { name: 'keywords', content: '红狮金业,黄金交易知识,贵金属交易技巧,贵金属投资方法,红狮课堂,现货黄金,现货白银,财经学院。' },  
        { name: 'description', content: '红狮金业为用户提供实时行情资讯、专业解读，此外财经教学栏目为用户提供炒金知识,小白高阶等课程，专注于为客户提供更丰富的投资学习环境，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
  data() {
    return {
      control:1,
    }
  },
  methods:{
    changeModel(key){
      this.control = key;
    }
  },
  created(){
    // 传参控制显示顶部和底部；
    this.$emit('controlShow', false);
    // 通过上个页面传来的参数进行control的控制；
    if(this.$route.query.key !== undefined){
      this.control = Number(this.$route.query.key);
    }
  },
  watch: {
    $route(nv){
      console.log(nv.query.key);
      this.control = nv.query.key;
    }
  }
}
</script>

<style lang="less" scoped>
  .Market{
    width: 100%;
    height: auto;
    .bigImg{
      width: 100%;
      height: 600px;
      background: url('../../static/market/banner.png') no-repeat;
      background-size: cover;
    }
    .amTitle{
      width: 1416px;
      height: 100%;
      margin: 80px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 90px;
        span{
          color: #666666;
          font-size: 32px;
          letter-spacing: 2px;
          margin-bottom: 30px;
        }
        .clickSpan{
          color: #000000;
        }
      }
    }
    .contentMarket{
      width: 1416px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 500px) {
    .Market{
      width: 100%;
      height: auto;
      .bigImg{
        width: 100%;
        height: 450px;
        background: url('../../static/index/wapImg/maketBanner.jpg') no-repeat;
        background-size: cover;
      }
      .amTitle{
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        display: -webkit-box;
        margin: 30px auto;
        p{
          margin-right: 20px;
          span{
            font-size: 18px;
            margin-bottom: 10px;
            letter-spacing: 0;
          }
          img{
            height: 2px;
          }
        }
      }
      .amTitle::-webkit-scrollbar {
        display: none;
      }
      .contentMarket{
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 5px;
      }
    }
  }
</style>